<template>
  <template>
    <a-modal
      v-model:visible="showModal"
      title="OPC Device Configuration"
      :body-style="{ height: '50vh', overflowY: 'auto' }"
      ok-text="Save"
      :confirm-loading="saveButtonIsLoading"
      @ok="handleSubmit"
      @cancel="handleCancel"
    >
      <a-space direction="vertical" class="w-100">
        <a-form ref="formRef" :model="formData" :rules="validationRules()">
          <template v-for="field in opcFormFields">
            <div v-if="field === 'device'">
              <a-form-item
                :label="updateLabel(field)"
                has-feedback
                :label-col="{ span: 7 }"
                :wrapper-col="{ span: 17 }"
                style="margin-bottom: 0;"
                :name="field"
              >
                <a-select
                  v-model:value="formData[field]"
                  placeholder="Select Device"
                  class="w-100"
                  :options="unConfiguredDevicesOption"
                  show-search
                  :filter-option="filterOption"
                  @change="handleDeviceChange"
                  :disabled="editModeEnabled"
                >
                </a-select>
              </a-form-item>
            </div>
            <div v-else-if="field === anonymous_access">
              <a-form-item
                :label="updateLabel(field)"
                :label-col="{ span: 7 }"
                :wrapper-col="{ span: 17 }"
                style="margin-bottom: 0;"
              >
                <a-switch
                  v-model:checked="formData[field]"
                  @change="handleAnonymousAccessChange"
                />
              </a-form-item>
            </div>
            <div v-else>
              <a-form-item
                :label="updateLabel(field)"
                has-feedback
                :label-col="{ span: 7 }"
                :wrapper-col="{ span: 17 }"
                style="margin-bottom: 0;"
                autocomplete="off"
                :name="field"
              >
                <a-select
                  v-if="field === 'security_policy'"
                  v-model:value="formData[field]"
                  placeholder="Select Policy"
                  class="w-100"
                  :options="securityPolicy"
                  show-search
                  :filter-option="filterOption"
                >
                </a-select>
                <a-input-password
                  v-else-if="field === 'password'"
                  v-model:value="formData[field]"
                  autocomplete="off"
                  type="password"
                  placeholder="*****"
                  :disabled="isInputDisabled(field)"
                />
                <a-input
                  v-else
                  v-model:value="formData[field]"
                  :disabled="isInputDisabled(field)"
                  @change="handleInputChange(field)"
                />
              </a-form-item>
            </div>
          </template>
        </a-form>
      </a-space>
    </a-modal>
  </template>
  <a-row :gutter="[0, 16]">
    <a-col span="24">
      <a-input
        placeholder="Search Device"
        v-model:value="searchDevice"
        class="w-25"
      >
        <template #suffix>
          <search-outlined style="color: rgba(0, 0, 0, 0.45)" />
        </template>
      </a-input>
      <a-button
        type="primary"
        class="float-right"
        :disabled="fetchDevices"
        @click="addDevice"
        >Add Device</a-button
      >
    </a-col>
    <a-col span="24">
      <a-table
        :columns="columns"
        :data-source="configuredDeviceList"
        :loading="isLoadingDevices"
        :scroll="{ x: 'max-content' }"
        :pagination="{ position: ['bottomCenter'] }"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'device'">
            {{ record.device_name }}
          </template>
          <template v-if="column.key === 'url'">
            <a-tag color="blue"
              >opc.tcp://{{ record.host }}:{{ record.port }}/{{
                record.endpoint
              }}</a-tag
            >
          </template>
          <template v-else-if="column.key === 'operation'">
            <a-space>
              <a-button
                class="text-white"
                type="primary"
                @click="editDevice(record, index)"
              >
                Edit
              </a-button>
              <a-button type="primary" danger @click="showDeletePopup = true">
                <a-popconfirm
                  v-modal:visible="showDeletePopup"
                  title="Are you sure delete the device?"
                  ok-text="Delete"
                  cancel-text="Cancel"
                  @confirm="handleDeleteDevice(record, index)"
                  @cancel="showDeletePopup = false"
                  >Delete
                </a-popconfirm>
              </a-button>
            </a-space>
          </template>
        </template>
      </a-table>
    </a-col>
  </a-row>
</template>

<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { SearchOutlined } from '@ant-design/icons-vue';
import DeviceService from 'src/services/device';
import IoTService from '../../../../services/iot';
import {
  OpcConnectionColumns,
  OpcConfigurationFields,
  OpcSecurityPolicy
} from './config';

export default defineComponent({
  inject: ['toast'],
  components: { SearchOutlined },
  setup() {
    return {
      columns: OpcConnectionColumns,
      opcFormFields: OpcConfigurationFields,
      securityPolicy: OpcSecurityPolicy
    };
  },
  data() {
    return {
      showModal: false,
      showForm: false,
      saveButtonIsLoading: false,
      showDeletePopup: false,
      editModeEnabled: false,
      fetchDevices: false,
      isLoadingDevices: false,
      selectedDeviceId: null,
      selectedDevice: null,
      selectedOpcDevice: null,
      searchDevice: '',
      anonymous_access: 'anonymous_access',
      deviceList: [],
      unConfiguredDevices: [],
      unConfiguredDevicesOption: [],
      formData: {},
      configuredDevices: [],
      toastTimeoutDuration: 3000,
      errorMessage: 'An unexpected error has occured. Please refresh the page.'
    };
  },
  created() {
    this.setFormData();
    this.setConfiguredDevices();
  },
  computed: {
    ...mapGetters(['organization']),
    configuredDeviceList() {
      if (!this.configuredDevices) {
        return [];
      }
      const searchLowerCase = this.searchDevice.toLowerCase().trim();
      return this.configuredDevices.filter(device =>
        device.device_name.toLowerCase().includes(searchLowerCase)
      );
    }
  },
  watch: {
    unConfiguredDevices(device) {
      this.unConfiguredDevicesOption = device.map(element => ({
        value: element.Device,
        label: element.display_name || element.Serial_number
      }));
    }
  },
  methods: {
    handleInputChange(field) {
      if (this.formData[field] !== '' && this.formData[field] !== undefined)
        return;
      this.formData[field] = null;
    },
    validationRules() {
      return {
        device: [{ required: true }],
        host: [{ required: true }],
        port: [
          { required: true, validator: this.validatePort, trigger: 'change' }
        ],
        endpoint: [{ required: true }],
        security_policy: [
          { required: true, message: "'security policy' is required" }
        ],
        username: [{ required: !this.formData?.anonymous_access || false }],
        password: [{ required: !this.formData?.anonymous_access || false }]
      };
    },
    async validatePort(_rule, value) {
      value = this.formData['port'];
      if (
        value === null ||
        value === '' ||
        value === undefined ||
        !this.containsOnlyDigits(value)
      ) {
        return Promise.reject('Please input digits');
      } else return Promise.resolve();
    },
    setFormData() {
      this.formData = this.opcFormFields.reduce((acc, field) => {
        if (field === this.anonymous_access) acc[field] = true;
        else acc[field] = null;
        return acc;
      }, {});
    },
    async setDeviceList() {
      this.fetchDevices = true;
      this.deviceList = await this.getAllDevices();
      if (this.deviceList === null) return;
      this.fetchDevices = false;
    },
    async setConfiguredDevices() {
      try {
        this.toast.info('Getting devices. Please wait.', {
          timeout: this.toastTimeoutDuration
        });
        this.isLoadingDevices = true;
        await this.setDeviceList();
        await this.getOPCDevices();
        this.isLoadingDevices = false;
      } catch (error) {
        this.isLoadingDevices = false;
        return this.toast.error(this.errorMessage, {
          timeout: this.toastTimeoutDuration
        });
      }
    },
    async getOPCDevices() {
      this.isLoadingDevices = true;
      const [error, data] = await IoTService.getAllOPCDevices();
      if (error) {
        this.isLoadingDevices = false;
        this.toast.error('Failed to fetch the OPC devices.', {
          timeout: this.toastTimeoutDuration
        });
        return;
      }
      this.isLoadingDevices = false;
      this.setConfiguredDevicesList(data);
    },
    setConfiguredDevicesList(data) {
      this.configuredDevices = data;
    },
    async getAllDevices() {
      const [error, data] = await DeviceService.fetchAllDevicesOfOrg(
        this.organization,
        true
      );
      if (error) {
        return null;
      }
      return data.filter(device => device.Type !== 'departmental device');
    },
    async addDevice() {
      try {
        this.resetFormValues();
        await this.setUnconfiguredDevices();
        this.showModal = true;
      } catch (error) {
        return this.toast.error(this.errorMessage, {
          timeout: this.toastTimeoutDuration
        });
      }
    },
    async setUnconfiguredDevices() {
      this.unConfiguredDevices = this.deviceList.filter(device => {
        if (
          device?.Device !== null &&
          this.configuredDevices.find(
            opcDevice => opcDevice?.device === device?.Device
          ) === undefined
        )
          return device;
      });
    },
    displayForm() {
      this.showForm = true;
    },
    handleDeviceChange(value) {
      this.selectedDevice = this.unConfiguredDevicesOption.find(
        device => device.value === value
      );
    },
    updateLabel(str) {
      if (str === '' || str === null || str === undefined) return str;
      str = str.split('_').join(' ');
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    filterOption(input, option) {
      return option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    handleAnonymousAccessChange(value) {
      if (value === false) return;
      this.formData['username'] = null;
      this.formData['password'] = null;
    },
    resetFormValues() {
      try {
        this.setFormData();
        this.$refs?.formRef?.clearValidate();
        this.selectedDeviceId = null;
        this.selectedDevice = null;
        this.selectedOpcDevice = null;
      } catch (error) {
        return this.toast.error(this.errorMessage, {
          timeout: this.toastTimeoutDuration
        });
      }
    },
    containsOnlyDigits(str) {
      const regex = /^[0-9]+$/;
      return regex.test(str);
    },
    async handleSubmit() {
      try {
        try {
          await this.$refs?.formRef?.validate();
        } catch (error) {
          return this.toast.error('Please provide required fields.', {
            timeout: this.toastTimeoutDuration
          });
        }
        await this.handleSubmitOperation();
      } catch (error) {
        this.performResetModal();
        return this.toast.error(this.errorMessage, {
          timeout: this.toastTimeoutDuration
        });
      }
    },
    async handleSubmitOperation() {
      this.saveButtonIsLoading = true;
      const isSuccess = await this.performAddOrUpdate();
      this.performResetModal();
      if (isSuccess) await this.getOPCDevices();
    },
    performResetModal() {
      this.resetFormValues();
      this.editModeEnabled = false;
      this.saveButtonIsLoading = false;
      this.showModal = false;
    },
    async performAddOrUpdate() {
      if (this.editModeEnabled) {
        return await this.handleUpdateDevice();
      }
      return await this.handleAddDevice();
    },
    async handleAddDevice() {
      const [error, data] = await this.addDeviceApi();
      if (error) {
        const isMessage = error?.response?.data?.message || '';
        this.toast.error(`Failed to add the OPC device. ${isMessage}`, {
          timeout: this.toastTimeoutDuration
        });
        return false;
      }
      this.toast.success(
        `Successfully added the device ${this.selectedDevice.label}.`,
        {
          timeout: this.toastTimeoutDuration
        }
      );
      return true;
    },
    async handleUpdateDevice() {
      const index = this.configuredDevices.findIndex(
        device => device.id === this.selectedOpcDevice.id
      );
      if (index === -1) {
        this.toast.error('Unable to find the configure device.', {
          timeout: this.toastTimeoutDuration
        });
        return false;
      }
      const [error, data] = await this.updateDeviceApi();
      if (error) {
        const isMessage = error?.response?.data?.message || '';
        this.toast.error(`Failed to update the OPC device. ${isMessage}`, {
          timeout: this.toastTimeoutDuration
        });
        return false;
      }
      this.toast.success('Edit successfully.', {
        timeout: this.toastTimeoutDuration
      });
      return true;
    },
    handleCancel() {
      this.performResetModal();
    },
    editDevice(record, index) {
      try {
        this.editModeEnabled = true;
        this.selectedOpcDevice = record;
        Object.keys(record).forEach(key => {
          if (key === 'device_name') this.formData.device = record.device_name;
          else if (key !== 'device') this.formData[key] = record[key];
        });
        this.$nextTick(() => {
          this.showModal = true;
        });
      } catch (error) {
        this.performResetModal();
        this.toast.error(this.errorMessage, {
          timeout: this.toastTimeoutDuration
        });
      }
    },
    async handleDeleteDevice(record, index) {
      try {
        const [error, data] = await IoTService.deleteOPCDevice(record.id);
        if (error) {
          this.toast.error('Failed to delete the OPC device.', {
            timeout: this.toastTimeoutDuration
          });
          return;
        }
        this.configuredDevices = this.configuredDevices.filter(
          opcDevice => opcDevice.id !== record.id
        );
        this.toast.success('Device deleted successfully.', {
          timeout: this.toastTimeoutDuration
        });
      } catch (error) {
        return this.toast.error(this.errorMessage, {
          timeout: this.toastTimeoutDuration
        });
      }
    },
    isInputDisabled(field) {
      return (
        (field === 'username' || field === 'password') &&
        this.formData[this.anonymous_access] === true
      );
    },
    async addDeviceApi() {
      const payload = {
        ...this.formData
      };
      return await IoTService.addOPCDevice(payload);
    },
    async updateDeviceApi() {
      if (this.selectedOpcDevice === null) {
        this.toast.error('Unable to get device. Aborting edit.', {
          timeout: this.toastTimeoutDuration
        });
        return;
      }
      const payload = {
        ...this.formData
      };
      payload.device = this.selectedOpcDevice.device;
      return await IoTService.updateOPCDevice(
        this.selectedOpcDevice.id,
        payload
      );
    }
  }
});
</script>
