export const columns = [
  {
    title: 'Tag Name',
    dataIndex: 'tagName',
    key: 'tagName',
    align: 'center'
  },
  {
    title: 'Tag Address',
    dataIndex: 'tagId',
    key: 'tagId',
    align: 'center'
  },
  {
    title: 'Device',
    dataIndex: 'serial_number',
    key: 'serial_number',
    align: 'center',
    width: '20%'
  },
  // {
  //   title: 'Task',
  //   dataIndex: 'taskName',
  //   key: 'taskName',
  //   align: 'center',
  // },
  {
    title: 'Trigger Type',
    dataIndex: 'triggerOn',
    key: 'triggerOn',
    customRender: ({ text, record }) => {
      return text ? text : 'N/A';
    },
    align: 'center'
  },
  {
    title: 'Operation',
    dataIndex: 'operation',
    key: 'operation',
    align: 'center',
    width: '15%'
  }
];

export const OpcConnectionColumns = [
  {
    title: 'Device',
    dataIndex: 'device',
    key: 'device',
    width: '40%'
  },
  {
    title: 'Url',
    dataIndex: 'url',
    key: 'url',
    width: '50%'
  },
  {
    title: 'Actions',
    dataIndex: 'operation',
    key: 'operation',
    width: '10%'
  }
];

export const OpcConfigurationFields = [
  'device',
  'host',
  'port',
  'endpoint',
  'anonymous_access',
  'username',
  'password',
  'security_policy'
];
export const OpcSecurityPolicy = [
  { value: 'None', label: 'None' },
  { value: 'Basic256Sha256', label: 'Basic256Sha256' }
];
